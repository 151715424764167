import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['directionOptions', 'submitButton']
  initialize() {
    this.imagePreview = document.getElementById('image-preview')
    this.checkbox = document.getElementsByName('option[]')
    this.downloadButton = document.getElementById('download-button')
  }

  chooseDirection() {
    const values = []
    this.checkbox.forEach((item) => {
      if (item.checked) {
        values.push(item.value)
      }
    })
    this.imagePreview.classList.remove('flip', 'flop')
    if (values.length === 0) {
      this.submitButtonTarget.classList.add('hidden')
      return
    }
    const input = document.getElementById('direction')
    input.value = values.join(',')
    
    this.imagePreview.classList.add(...values)
    this.downloadButton.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }
}