import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button']

  initialize() {
    this.index = 0
  }

  connect() {
    console.log("Hello, Stimulus!", this.element)
  }

  update() {
    this.index++
    this.buttonTarget.textContent = `Hello(${this.index})`
  }
}