import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class extends Controller {
  static targets = ['format', 'form', 'submitButton']
  initialize() {
    this.alertNode = document.querySelector('.alert-block')
    this.downloadButton = document.getElementById('download-button')
  }

  selectFormat(e) {
    const value = e.target.value
    const input = document.getElementById('format-input')
    input.value = value
    this.downloadButton.classList.add('hidden')
    if (value !== '') {
      this.submitButtonTarget.classList.remove('hidden')
    } else {
      this.submitButtonTarget.classList.add('hidden')
    }
  }

  submit() {
    console.log('进入 submit 了！')
    if (this.formatTarget.value === '') {
      Swal.fire({title: '警告', text: '请选择需要转换的格式'})
      return
    }
    Rails.fire(document.getElementById('hidden-submit-button'), 'click')
  }
}