import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['rangeInput', 'filename', 'submitButton']

  initialize() {
    this.downloadButton = document.getElementById('download-button')
  }

  chooseQuality(e) {
    this.downloadButton.classList.add('hidden')
    const value = e.target.value
    const input = document.getElementById('quality-input')
    input.value = value
    this.submitButtonTarget.classList.remove('hidden')
  }

  fileChosen() {
    const filename = this.filenameTarget.value
    const arr = filename.split('.')
    const fileType = arr[arr.length-1].toLowerCase()
    console.log("------", filename, fileType)
    if ( fileType === 'jpg' || fileType === 'jpeg') {
      this.rangeInputTarget.classList.remove('hidden')
      this.rangeInputTarget.classList.add('d-flex')
    } else {
      this.rangeInputTarget.classList.add('hidden')
      this.rangeInputTarget.classList.remove('d-flex')
    }
  }
}