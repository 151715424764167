import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['fileInput', 'fileName', 'form', 'formatInput', 'chooseFileButton']

  initialize() {
    this.allowedFormats = ['jpg', 'png', 'gif', 'psd', 'webp', 'pdf', 'bmp', 'ico', 'tiff']
    this.canChooseFormats = []
    this.alertNode = document.querySelector('.alert-block')
    this.file = null
    this.imagePreview = document.getElementById('image-preview')
    this.formatsSelect = document.getElementById('formats-select')
    this.cos = this.getCos()
    this.bucket = document.querySelector('body').dataset.bucket
    this.uploadButton = document.getElementById('upload-button')
    this.waitingButton = document.getElementById('waiting-button')
    this.downloadButton = document.getElementById('download-button')
  }

  choose() {
    this.fileInputTarget.click()
  }

  fileChosen() {
    this.downloadButton.classList.add('hidden')
    this.chooseFileButtonTarget.classList.add('hidden')
    this.resetInputValue()
    this.file = this.fileInputTarget.files[0]
    if (this.file) {
      this.fileNameTarget.textContent = this.file.name
      const hiddenFilename = document.getElementById('hidden-filename')
      const originalFilename = document.getElementById('original_filename')
      originalFilename.value = this.file.name
      if (hiddenFilename) {
        hiddenFilename.value = this.file.name
        const event = new Event('change')
        hiddenFilename.dispatchEvent(event, true, true)
      }
      
      // 设置可选图片格式
      if (this.formatsSelect) {
        this.setSelectOption()
      }
      // 显示上传的图片
      if (this.imagePreview) {
        this.displayImage()
      }
      this.fileNameTarget.parentNode.classList.remove('d-none')
    } else {
      this.fileNameTarget.parentNode.classList.add('d-none')
    }
  }

  resetInputValue() {
    
  }

  selectFormat(e) {
    const value = e.target.value
    this.formatInputTarget.value = value
  }

  submit() {
    console.log('触发 click！')
    this.uploadButton.classList.add('hidden')
    this.waitingButton.classList.remove('hidden')
    this.uploadFile(this.file)
  }

  uploadFile(file) {
    console.log('file is: ', file)
    const that = this
    const key = this.createKey('/upload', file.name)
    console.log("upload")
    this.cos.putObject({
      Bucket: that.bucket, /* 必须 */
      Region: 'ap-shanghai',     /* 存储桶所在地域，必须字段 */
      Key: key,              /* 必须 */
      StorageClass: 'STANDARD',
      Body: file, // 上传文件对象
      onProgress: function(progressData) {
          // console.log(JSON.stringify(progressData));
      }
  }, function(err, data) {
      if (err) {
        alert(err)
        return
      }
      document.getElementById('upload_key').value = key
      Rails.fire(that.formTarget, 'submit')
  });

  }

  createKey(path, name) {
    const time = new Date().getTime()
    const rand = Math.ceil(Math.random()*1000)
    return `${path}/${time + rand}-${name}`
  }

  getCos() {
    return new COS({
      // getAuthorization 必选参数
      getAuthorization: function (options, callback) {
          // 异步获取临时密钥
          // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
          // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
          // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
  
          const url = '/qcloud/token'; // url替换成您自己的后端服务
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.onload = (e) => {
            // console.log(JSON.parse(e.target.responseText))
              let data
              try {
                data = JSON.parse(e.target.responseText);
                  // var credentials = data.credentials;
              } catch (e) {
              }
              // if (!data || !credentials) {
              //   return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
              // };
              callback(data);
          };
          xhr.send();
      }
  });
  }

  composeOption(values) {
    let html = '<option value="">请选择需要转换的格式</option>'
    values.forEach((item) => {
      html += `<option value="${item}">${item}</option>`
    })
    return html
  }

  displayImage() {
    const reads = new FileReader()
    reads.readAsDataURL(this.file)
    const that = this
    reads.onload = function(e) {
      that.imagePreview.src = this.result
    }
  }

  setSelectOption() {
    const arr = this.file.name.split('.')
    const format = arr[arr.length - 1].toLowerCase()
    this.canChooseFormats = this.allowedFormats.filter((item) => {
      if (format === 'jpg' || format === 'jpeg') {
        return item !== 'jpg'
      } else {
        return item !== format
      }
    })
    this.formatsSelect.innerHTML = this.composeOption(this.canChooseFormats)
  }
}