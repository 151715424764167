// console.log('Vite ⚡️ Rails')
// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// // Example: Load Rails libraries in Vite.
// //
import '../application.scss'
import $ from 'jquery/dist/jquery'
window.jQuery = window.$ = $
import Rails from '@rails/ujs'
window.Rails = Rails
import '@popperjs/core'
import 'bootstrap'
import '~/controllers/index.js'
// //
import Turbolinks from 'turbolinks'
// // import ActiveStorage from '@rails/activestorage'
// //
// // // Import all channels.
// // const channels = import.meta.globEager('./**/*_channel.js')
// //
Rails.start()
Turbolinks.start()


// // ActiveStorage.start()

// // Example: Import a stylesheet in app/frontend/index.css
// import '../application.scss'
