import { Controller } from "@hotwired/stimulus"
// import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class extends Controller {
  static targets = ['angleOptions', 'customDegree', 'submitButton']
  initialize() {
    this.imagePreview = document.getElementById('image-preview')
    // Swal.fire({title: '警告', text: '请勿！'})
  }

  selectAngle(e) {
    const value = e.target.value
    if (!value) {
      this.submitButtonTarget.classList.add('hidden')
      return
    }
    if (value === 'custom') {
      this.customDegreeTarget.type = 'number'
      return
    }
    this.customDegreeTarget.type = 'hidden'
    const input = document.getElementById('angle')
    input.value = value
    this.imagePreview.style.transform = `rotate(${value}deg)`
    this.submitButtonTarget.classList.remove('hidden')
  }

  setAngle(e) {
    const value = e.target.value
    const input = document.getElementById('angle')
    input.value = value
    this.imagePreview.style.transform = `rotate(${value}deg)`
    if (!value) {
      this.submitButtonTarget.classList.add('hidden')
    } else {
      this.submitButtonTarget.classList.remove('hidden')
    }
  }
}