import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submitButton']
  initialize() {
    this.imagePreview = document.getElementById('image-preview')
  }

  selectLang(e) {
    const value = e.target.value
    if (!value) {
      this.submitButtonTarget.classList.add('hidden')
      return
    }
    const input = document.getElementById('lang-input')
    input.value = value
    this.submitButtonTarget.classList.remove('hidden')
  }
}